<template>
  <v-app>
    <v-container>
      <v-btn
        :loading="bulkLoading"
        color="primary"
        @click="selectFileDispatcher"
      >
        Upload file
      </v-btn>
      <input
        id="file"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :style="{ display: 'none' }"
        @change="handleFileChange"
      >
      <h3>Parsed Data (from the API):</h3>
      <pre>{{ bulkLoadData }}</pre>
    </v-container>
  </v-app>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'BulkUpload',
    data () {
      return {
        file: undefined,
      }
    },
    computed: {
      ...mapState([
        'bulkLoading',
        'bulkLoadData',
      ]),
    },
    methods: {
      ...mapActions([
        'setError',
        'bulkLoad',
      ]),
      async handleSubmit () {
        const formData = new FormData()
        formData.set('file', this.file)
        await this.bulkLoad({ formData })
      },
      selectFileDispatcher () {
        const fileOpener = document.getElementById('file')
        fileOpener.click()
      },
      async handleFileChange (e) {
        const file = e.target.files[0]
        this.file = file
        await this.handleSubmit()
      },
    },
  }
</script>
